<template>
  <div id="cupo-express">
    <div class="container-header">
      <router-link :to="{ name: 'Home' }">
        <img alt="Logo clover" src="@/assets/icons/logo-clover.svg" />
        <img
          width="80px"
          src="@/assets/icons/icon-quac-large_negro.svg"
          alt="logo quac"
          style="margin-left: 1.5rem;"
        />
      </router-link>
    </div>
    <div class="container-banner-cupoexpress">
      <div class="banner-cupoexpress">
        <div class="container-data-banner">
          <div class="container-text-banner-cupoexpress">
            <p class="p-1">CON TU CUPO PERSONAL</p>
            <h1 class="p-2-title">
              Tu cupo personal ahora es ¡Quac!
            </h1>
            <p>
              Regístrate ahora y recibe cupo pre-aprobado por<br />
              <b>$500.000</b> para tu primera compra.
            </p>
            <button class="bold" @click="$bvModal.show('modalcupo')">
              ¡QUIERO MI CUPO!
            </button>
          </div>
        </div>
        <img
          src="@/assets/imgs/cupoexpress/img-banner-cupoexpress-points.png"
          alt="Imagen cupo express clover"
          class="img-portada-banner"
        />
      </div>
    </div>
    <div class="container-card-data-info-cupoexpress justify-content-center">
      <div class="card-data-info-cupoexpress col-12 col-md-4">
        <div class="data-info-cupoexpress">
          <img
            src="@/assets/imgs/cupoexpress/img-data-info-cupoexpress-1.svg"
            alt=""
          />
          <h3>
            Tu cupo al <br />
            instante
          </h3>
          <span
            >Solicita tu cupo online y recibe<br />
            aprobación inmediata.</span
          >
          <span style="font-size: 12px"
            >(Sujeta a verificación de referencias)</span
          >
        </div>
      </div>
      <div class="card-data-info-cupoexpress col-12 col-md-4">
        <div class="data-info-cupoexpress">
          <img
            src="@/assets/imgs/cupoexpress/img-data-info-cupoexpress-2.svg"
            alt=""
          />
          <h3>
            Flexibilidad<br />
            en pagos
          </h3>
          <span
            >Pagos fáciles, quincenales<br />
            y asequibles.</span
          >
        </div>
      </div>
      <div class="card-data-info-cupoexpress col-12 col-md-4">
        <div class="data-info-cupoexpress">
          <img
            src="@/assets/imgs/cupoexpress/img-data-info-cupoexpress-3.svg"
            alt=""
          />
          <h3>
            Cuota de<br />
            manejo gratis
          </h3>
          <span
            >No te cobramos cuota de<br />
            manejo.</span
          >
        </div>
      </div>
      <div class="card-data-info-cupoexpress col-12 col-md-4">
        <div class="data-info-cupoexpress">
          <img
            src="@/assets/imgs/cupoexpress/img-data-info-cupoexpress-4.svg"
            alt=""
          />
          <h3>
            No tienes que<br />
            dar cuota inicial
          </h3>
          <span
            >Haz tu pedido y empieza a<br />
            pagarlo en 15 días.</span
          >
        </div>
      </div>
      <!-- <div class="card-data-info-cupoexpress col-12 col-md-4">
                <div class="data-info-cupoexpress">
                    <img src="@/assets/imgs/cupoexpress/img-data-info-cupoexpress-5.svg" alt="">
                    <p>Accede a marcas<br> exclusivas</p>
                    <span>Más de 30 marcas<br> exclusivas disponibles.</span>
                </div>
            </div> -->
      <div class="card-data-info-cupoexpress col-12 col-md-4">
        <div class="data-info-cupoexpress">
          <img
            src="@/assets/imgs/cupoexpress/img-data-info-cupoexpress-6.svg"
            alt=""
          />
          <h3>
            Proceso<br />
            100% Digital
          </h3>
          <span
            >¡Sin filas! Haz tus compras<br />
            y pagos online.</span
          >
        </div>
      </div>
    </div>
    <div class="container-what-is">
      <div class="container-data-what-is">
        <h2 class="mb-3"><b>¿Qué es? y ¿Cómo funciona?</b></h2>
        <p style="max-width: 500px">
          El cupo personal es un método de pago que puedes utilizar para pagar
          en cuotas las compras realizadas en nuestras tiendas físicas y online.
          <br />
          ¡Accede fácil a nuestras últimas colecciones, bolsos y accesorios!
          <br /><br />
          Usar tu cupo es
          <span style="font-weight: bold; color: #ef6d58">fácil y rápido</span>,
          solo debes seguir estos pasos:
        </p>
        <div class="container-steps">
          <div
            class="container-step"
            :class="selectImg == 1 ? 'container-step-select' : ''"
            @click="selectImg = 1"
          >
            <span>1</span>
            <p>Elige el producto que te gusta.</p>
          </div>
          <div
            class="container-step"
            :class="selectImg == 2 ? 'container-step-select' : ''"
            @click="selectImg = 2"
          >
            <span>2</span>
            <p>Agrégalo al carrito de compras.</p>
          </div>
          <div
            class="container-step"
            :class="selectImg == 3 ? 'container-step-select' : ''"
            @click="selectImg = 3"
          >
            <span>3</span>
            <p>Selecciona <b>Cupo personal</b> como tu método de pago.</p>
          </div>
        </div>
        <img
          src="@/assets/icons/icon-quac-large.svg"
          alt="logo quac"
          style="margin-top: 1rem;"
        />
      </div>
      <div id="img-cel" class="container-cel-cupoexpress pb-4 pb-md-0">
        <img
          id="img-bg-cel"
          src="@/assets/imgs/cupoexpress/bg-cel-cupoexpress.svg"
          class="bg-cel"
          alt=""
        />
        <img
          v-show="selectImg == 1"
          src="@/assets/imgs/cupoexpress/cel-cupoexpres-1.png"
          class="img-cel"
          alt="Elige el producto que te gusta."
        />
        <img
          v-show="selectImg == 2"
          src="@/assets/imgs/cupoexpress/cel-cupoexpres-2.png"
          class="img-cel"
          alt="Agrégalo al carrito de compras."
        />
        <img
          v-show="selectImg == 3"
          src="@/assets/imgs/cupoexpress/cel-cupoexpres-3.png"
          class="img-cel"
          alt="Selecciona Cupo personal como tu método de pago."
        />
        <img
          src="@/assets/imgs/cupoexpress/floating-cel-cupoexpress.png"
          class="decorador-cel"
          alt=""
        />
      </div>
    </div>
    <div class="container-registro-cupo">
      <img
        src="@/assets/imgs/cupoexpress/bg-cupoexpress-banner.png"
        class="mx-auto"
        alt=""
      />
      <div class="container-data-cupo">
        <h2><b>Regístrate ahora y recibe un cupo pre-aprobado por</b></h2>
        <p class="price-cupo mb-0">$500.000</p>
        <span
          >*Aplican términos y condiciones - Válido para nuevos clientes y
          sujeto a estudio de crédito.</span
        >
        <div class="mt-4">
          <button
            class="btn-login-cupoexpress"
            @click="$bvModal.show('modalcupo')"
          >
            ¡QUIERO MI CUPO!
          </button>
        </div>
      </div>
    </div>
    <div class="container-beneficios">
      <h2 style="margin-bottom: 80px; font-weight: bold">
        Más beneficios de los que crees
      </h2>
      <div class="container-steps-beneficios">
        <div class="col-12 col-md-4 px-2 mb-5">
          <div class="container-step-beneficios">
            <img src="@/assets/imgs/cupoexpress/img-beneficios-1.svg" alt="" />
            <p>
              Descuento de<br />
              cumpleaños
            </p>
            <span
              >Recibe un descuento exclusivo para<br />
              que estrenes el día más importante<br />
              del año.</span
            >
          </div>
        </div>
        <div class="col-12 col-md-4 px-2 mb-5">
          <div class="container-step-beneficios">
            <img src="@/assets/imgs/cupoexpress/img-beneficios-2.svg" alt="" />
            <p>
              Acceso anticipado a<br />
              productos únicos
            </p>
            <span
              >¿Quieres ser el primero el estrenar?<br />
              Recibe acceso anticipado a<br />
              productos nuevos y únicos.</span
            >
          </div>
        </div>
        <div class="col-12 col-md-4 px-2 mb-5">
          <div class="container-step-beneficios">
            <img src="@/assets/imgs/cupoexpress/img-beneficios-3.svg" alt="" />
            <p>
              Nuevo: Compra hoy y<br />
              recibe hoy*
            </p>
            <span
              >Si realizas tu compra en la mañana,<br />
              recíbela el mismo día en la tarde.</span
            ><br />
            <span style="color: #9b9b9b; font-size: 12px"
              >*Aplican zonas de cobertura y sujeto a disponibilidad</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="conatiner-preguntas-cupo">
      <h2 class="text-center mb-5">Preguntas frecuentes</h2>
      <div class="accordion-cupo" role="tablist">
        <b-card
          v-for="(item, index) in slicePreguntas
            ? preguntasFrecuentes.slice(0, 7)
            : preguntasFrecuentes"
          :key="index"
          no-body
        >
          <b-card-header header-tag="header" role="tab">
            <b-button
              v-b-toggle="'accordion-' + index"
              block
              variant="Secondary"
              class="container-button-cupo-acoordeon rounded-0 text-left"
            >
              <b style="color: #001f5d;" class="text-start">{{
                item.titulo
              }}</b>
              <span class="ml-auto when-open float-right img-icon-acoordeon"
                ><img src="@/assets/imgs/cupoexpress/hidde-tablist.svg" alt=""
              /></span>
              <span class="ml-auto when-closed float-right img-icon-acoordeon"
                ><img src="@/assets/imgs/cupoexpress/show-tablist2.svg" alt=""
              /></span>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-' + index"
            class="px-4 p-3"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text v-html="item.descripcion"> </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div class="text-center py-4">
        <button class="outline-cupo" @click="slicePreguntas = !slicePreguntas">
          {{ slicePreguntas ? 'CARGAR MÁS PREGUNTAS' : 'VER MENOS' }}
        </button>
      </div>
    </div>
    <!-- <div class="container-footer">
      <div class="container-data-footer">
        <div>
          <h2 class="mt-3">¡Compra ahora y paga después!</h2>
          <span style="color: #9b9b9b; font-size: 12px"
            >*Aplican terminos y condiciones.</span
          >
        </div>
        <div class="container-btn-cupo">
          <button @click="$bvModal.show('modalcupo')">¡QUIERO MI CUPO!</button>
        </div>
      </div>
    </div> -->
    <breakin-cupo />
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCollapse,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import BreakinCupo from '@/components/Templates/BreakinCupo.vue'
export default {
  name: 'Cart',
  components: {
    BCollapse,
    BCard,
    BCardBody,
    BCardHeader,
    BButton,
    BCardText,
    BreakinCupo,
  },
  data() {
    return {
      selectImg: 1,
      brands: [],
      preguntasFrecuentes: [],
      slicePreguntas: true,
    }
  },
  watch: {
    selectImg() {
      document.getElementById('img-bg-cel').style.animation = 'show-fade 1.5s'
      document.getElementById('img-bg-cel').style.display = 'flex'
    },
  },
  created() {
    // this.$gtag.pageview(this.$route.fullPath);
  },
  beforeDestroy() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover'
    this.$store.state.layouts.menuLayoutClover = 'menu-clover'
  },
  async mounted() {
    this.$store.state.layouts.footerLayoutClover = 'NoLayout'
    this.$store.state.layouts.menuLayoutClover = 'NoLayout'
    window.addEventListener('scroll', this.scrollAnimation)
    this.axios({
      method: 'Get',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/politicas',
    })
      .then(response => {
        this.preguntasFrecuentes = response.data[6].items
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    async scrollAnimation() {
      let img = document.getElementById('img-cel')
      if (img && window.innerHeight - img.offsetTop + window.scrollY > 0) {
        if (window.innerHeight - img.offsetTop + window.scrollY >= 253) {
          document.getElementById('img-bg-cel').style.animation =
            'show-fade 1.5s'
          document.getElementById('img-bg-cel').style.display = 'flex'
        } else {
          document.getElementById('img-bg-cel').style.animation =
            'show-fade-out 1.5s'
          await setTimeout(() => {
            if (window.innerHeight - img.offsetTop + window.scrollY < 253) {
              document.getElementById('img-bg-cel').style.display = 'none'
            }
          }, 1500)
        }
      } else {
        document.getElementById('img-bg-cel').style.display = 'none'
      }
    },
  },
}
</script>
<style lang="scss">
.container-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70px;
  position: absolute;
  top: 0;
  z-index: 1;
  background-color: #0000000a;
}
.btn-login-cupoexpress {
  background-color: #fbb252;
  min-height: 48px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-radius: 48px;
  border: none;
  color: black;
  font-weight: bold;
}
.container-banner-cupoexpress {
  position: relative;
  height: 80vh;
  min-height: 650px;
  max-height: 650px;
  max-width: 1920px;
  margin-left: auto;
  display: flex;
  margin-right: auto;
  background-image: url('./../assets/imgs/cupoexpress/bg-banner-cupoexpress-morado-clover.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.container-banner-cupoexpress .btn-login-cupoexpress {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.banner-cupoexpress {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}
.container-text-banner-cupoexpress {
  position: relative;
}
.container-text-banner-cupoexpress .p-1 {
  letter-spacing: 3px;
  margin-bottom: 0;
}
.container-text-banner-cupoexpress .p-2-title {
  font-size: 60px;
  max-width: 507px;
  font-weight: bold;
  line-height: 85px;
  position: relative;
  z-index: 1;
}
.container-text-banner-cupoexpress .decorador-title-cupoexpress {
  position: relative;
  font-family: 'Abril Fatface';
  z-index: 1;
}
.container-text-banner-cupoexpress .decorador-title-cupoexpress::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 25px;
  background-color: #df8be5;
  bottom: 0;
  z-index: -1;
}
.container-data-banner button {
  position: relative;
  color: white;
  background-color: #7633ff;
  border: none;
  min-height: 48px;
  border-radius: 48px;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  font-size: 14px;
  font-weight: bold;
}
.banner-cupoexpress {
  margin-top: auto;
}
@media (min-width: 1450px) {
  .banner-cupoexpress {
    margin-right: auto;
  }
}
@media (max-width: 1300px) {
  .banner-cupoexpress {
    margin-left: 3rem;
  }
  .banner-cupoexpress .container-data-banner {
    width: 500px;
  }
  .banner-cupoexpress.img-portada-banner {
    margin-right: 0;
  }
}
@media (max-width: 1000px) {
  .container-banner-cupoexpress {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5rem;
  }
  .container-text-banner-cupoexpress .p-2-title {
    font-size: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  .banner-cupoexpress {
    margin-left: 2rem;
    margin-right: 1rem;
    margin-bottom: 3rem;
  }
  .banner-cupoexpress .container-data-banner {
    width: 100%;
  }
  .banner-cupoexpress .container-data-banner img {
    top: 40%;
    right: 20%;
  }
  .img-portada-banner {
    display: none;
  }
}
.container-card-data-info-cupoexpress {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  margin-top: -95px;
  margin-bottom: 7rem;
}
.card-data-info-cupoexpress {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
.data-info-cupoexpress {
  width: 100%;
  position: relative;
  background-color: #ffcccc;
  border-radius: 10px;
  min-height: 190px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.data-info-cupoexpress:hover {
  background-color: #f2afaf;
}
.data-info-cupoexpress:hover span {
  visibility: visible;
  opacity: 1;
  height: auto;
}
.data-info-cupoexpress img {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.data-info-cupoexpress h3 {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  width: 100%;
  cursor: default;
}
.data-info-cupoexpress span {
  text-align: center;
  visibility: hidden;
  opacity: 0;
  height: 0;
  cursor: default;
  transition: visibility 0s, opacity 0.5s linear;
}
.container-what-is {
  width: 80%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 3rem;
}
.container-data-what-is {
  margin-bottom: 4rem;
  position: relative;
  z-index: 10;
}
.container-step {
  display: flex;
  align-items: center;
  border-radius: 37px;
  padding-left: 1rem;
  height: 54px;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.container-step span {
  background-color: white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  max-height: 28px;
  min-width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.container-step p {
  margin-bottom: 0;
  padding-left: 1rem;
}
.container-step-select {
  background-color: #f5f5f5;
  width: 100%;
}
.container-step-select p {
  font-weight: bold;
}
.container-cel-cupoexpress {
  position: relative;
  margin-bottom: 2rem;
}
.bg-cel {
  position: absolute;
  left: 50%;
  top: 50%;
  display: none;
  transform: scale(1.2) translate(-45%, -55%);
  z-index: 0;
}
.img-cel {
  position: relative;
  z-index: 1;
  min-width: 292px;
  aspect-ratio: 292/625;
}
.decorador-cel {
  position: absolute;
  left: 50%;
  transform: scale(1.1) translateX(-50%);
  z-index: 2;
  animation: up-down 6s infinite linear;
}
@keyframes up-down {
  0% {
    top: 2%;
  }
  50% {
    top: 8%;
  }
  100% {
    top: 2%;
  }
}
@keyframes show-fade {
  from {
    transform: scale(0.7) translate(-45%, -55%);
    top: 25%;
    left: 25%;
  }
  to {
    transform: scale(1.2) translate(-45%, -55%);
    left: 50%;
    top: 50%;
  }
}
@keyframes show-fade-out {
  from {
    transform: scale(1.2) translate(-45%, -55%);
    left: 50%;
    top: 50%;
  }
  to {
    transform: scale(0.7) translate(-45%, -55%);
    top: 25%;
    left: 25%;
  }
}
@media (max-width: 1000px) {
  .container-what-is {
    width: 90%;
  }
  .container-cel-cupoexpress .bg-cel {
    transform: scale(1.2) translate(-40%, -55%);
  }
  @keyframes show-fade {
    from {
      transform: scale(0.7) translate(-40%, -55%);
      top: 25%;
      left: 25%;
    }
    to {
      transform: scale(1.2) translate(-40%, -55%);
      left: 50%;
      top: 50%;
    }
  }
  @keyframes show-fade-out {
    from {
      transform: scale(1.2) translate(-40%, -55%);
      left: 50%;
      top: 50%;
    }
    to {
      transform: scale(0.7) translate(-40%, -55%);
      top: 25%;
      left: 25%;
    }
  }
}
.container-registro-cupo {
  background-color: #f2e9fd;
  display: flex;
  position: relative;
  margin-bottom: 3rem;
}
.container-registro-cupo img {
  position: relative;
}
.container-data-cupo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.container-data-cupo .price-cupo {
  font-size: 150px;
  line-height: 150px;
  font-weight: bold;
  color: #1e1c24;
}
@media (max-width: 1000px) {
  .container-registro-cupo {
    flex-wrap: wrap;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .container-data-cupo {
    width: 50%;
  }
  .container-data-cupo .price-cupo {
    font-size: 72px;
  }
}
@media (max-width: 780px) {
  #cupo-express {
    position: relative;
  }
  #cupo-express .container-breakin-cupo .breking-cupo {
    justify-content: flex-start;
  }
  #cupo-express .container-breakin-cupo .description-breakin-cupo {
    margin-left: 0;
    margin-top: 2rem;
  }
  #cupo-express .container-breakin-cupo .container-button-cupo {
    margin-left: auto;
  }
  .container-data-cupo {
    position: relative;
    transform: none;
    top: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
  }
  .container-registro-cupo img {
    display: none;
  }
}
.container-beneficios {
  width: 80%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-bottom: 4rem;
}
.container-steps-beneficios {
  display: flex;
  flex-wrap: wrap;
}
.container-step-beneficios {
  position: relative;
  padding: 0.4rem;
  padding-top: 56px;
  border-radius: 10px;
}
.container-step-beneficios p {
  font-size: 18px;
  font-weight: bold;
}
.container-step-beneficios img {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 80px;
  max-height: 80px;
}
.container-step-beneficios:hover {
  background-color: #ebedfa;
}
@media (max-width: 1000px) {
  .container-beneficios {
    width: 90%;
    padding-bottom: 50px;
  }
  .container-step-beneficios {
    height: 100%;
    background-color: #ebedfa;
  }
}
.conatiner-preguntas-cupo {
  background-color: #f2e9fd;
  padding-top: 3rem;
}
.accordion-cupo {
  max-width: 1200px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}
.accordion-cupo .collapsed > .when-open,
.accordion-cupo .not-collapsed > .when-closed {
  display: none;
}
.accordion-cupo .not-collapsed {
  background-color: #e2d4f3;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.accordion-cupo .card .show {
  background-color: #e2d4f3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 0 !important;
}
.accordion-cupo .card {
  border: none;
  border-radius: 0;
  border-top: 1px solid #e2e2e2;
  background-color: transparent;
}
.accordion-cupo .card-header {
  border: none;
  padding: 0;
  background-color: transparent;
}
.outline-cupo {
  background-color: transparent;
  border: 1px solid #525ffb;
  color: #525ffb;
  min-height: 48px;
  border-radius: 48px;
  font-weight: bold;
  font-size: 14px;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
}
.container-button-cupo-acoordeon {
  display: flex !important;
  padding: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.container-button-cupo-acoordeon:focus {
  box-shadow: none !important;
}
.container-footer {
  background-color: #2b2850;
  min-height: 100px;
  width: 100%;
  margin-top: auto;
  display: flex;
  z-index: 3;
  bottom: 0;
}
.container-data-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1200px;
  padding-right: 2rem;
  padding-left: 2rem;
}
.container-data-footer h2 {
  font-size: 30px;
  font-weight: bold;
  color: white;
}
.img-icon-acoordeon {
  min-width: 60px;
  margin-left: 1rem;
}
.container-data-footer button {
  color: white;
  background-color: #ef6d58;
  border: none;
  min-height: 48px;
  border-radius: 48px;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  font-size: 14px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .container-card-data-info-cupoexpress {
    width: 90%;
  }
  .accordion-cupo {
    width: 100%;
    text-align: left;
  }
  .container-data-footer {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .container-btn-cupo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-right: 1rem;
  }
}
#modal-error-payment .modal-content {
  border-radius: 0px !important;
  padding: 2rem;
  border: none;
}
.modal-body {
  text-align: center;
}
.container-modal-mask-data .icon-error-modal {
  background-color: #f8dada;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /*border-radius: 10px; */
  margin-bottom: 2rem;
}
@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(361deg);
  }
}
</style>
